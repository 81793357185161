/// <reference path="references.ts"/>
var throttle = function (func, limit) {
    var inThrottle;
    return function () {
        var args = arguments;
        var context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(function () { return inThrottle = false; }, limit);
        }
    };
};
var main = {
    breadcrumbs: null,
    init: function () {
        main.installClickEventForDonateBtn();
        main.initAccordion();
        main.initContactLocation();
        main.installVideoFancybox();
        main.initCarousels();
        main.installClickEventForNewsViewMoreBtn();
        main.installClickEventForHearAboutUsBtn();
        main.installClickEventForPaymentMethodBtn();
        main.installClickEventForPaymentMethodRadioBtn();
        main.initVideoMagnificPopup();
        main.initPhotoGalleryPopup();
        main.initDisclaimerBtnsEventListener();
        main.storeOriginalBreadCrumbs();
        main.initResizeEvent();
        main.initHeader();
        main.initCmsDateFields();
        $('body').addClass('loaded');
        main.controlExitButton();
        main.initModalForAudio();
        main.initRedressNotice();
        main.prepareRecaptcha();
        main.footerMobileNavigation();
        main.handleNavigationBackdrop();
        main.handleMobileNavigation();
        main.watchSearchIcon();
        main.colourInvalidFormInputLabels();
        main.handleTopMenuOnScrollImproved();
        main.animateOnScroll();
    },
    /**
     * Prepare form for recaptcha validation
     */
    prepareRecaptcha: function () {
        var $recaptchaInputElm = $('form').find('[fieldname=recaptcha]');
        if ($recaptchaInputElm.length <= 0 || $recaptchaInputElm.val() == '') {
            return;
        }
        var $form = $recaptchaInputElm.closest('form');
        var $formButton = $form.find('[type=submit]');
        $formButton.addClass('g-recaptcha');
        $formButton.attr('data-sitekey', $recaptchaInputElm.val());
        $formButton.attr('data-action', 'submit');
        $formButton.on('click', { form: $form, key: $recaptchaInputElm.val() }, function (e) {
            e.preventDefault();
            // Check form validation
            if (!validateForm(e.data.form[0])) {
                return;
            }
            // do captcha validation
            grecaptcha.ready(function () {
                grecaptcha.execute(e.data.key, { action: 'submit' }).then(function (token) {
                    // Add your logic to submit to your backend server here.
                    axios.post('/captcha/validate', {
                        'token': token
                    }).then(function (response) {
                        if (response.data == 'true') {
                            // Set correct form action
                            e.data.form.attr('action', '/index.php?action=submitform');
                            // Include missing value
                            e.data.form.append('<input type="hidden" name="cms_form_submit" value="Submit">');
                            // Wait 1 second
                            window.setTimeout(function () {
                                e.data.form.submit();
                            }, 500);
                        }
                        else {
                            alert('Invisible recpatcha failed. Try again!');
                        }
                    });
                });
            });
        });
    },
    initRedressNotice: function () {
        $('#redress-notice').modal({
            showClose: false,
            clickClose: false,
            escapeClose: false,
        });
        $.modal.close();
        // Show notice if we can't find the following key on the local storage
        if (main.getCookie('redress-notice') === "") {
            $('#redress-notice').modal({
                showClose: false,
                clickClose: false,
                escapeClose: false,
            });
        }
        // Hide the banner on proceed button click
        $('#redress-notice-proceed-button').on('click', function (e) {
            e.preventDefault();
            main.setCookie('redress-notice', 'read', 2160);
            $.modal.close();
        });
    },
    initModalForAudio: function () {
        $('#audio-modal').easyModal();
        $('#audio-play-button').on('click', function () {
            $('#audio-modal').trigger('openModal');
        });
    },
    installClickEventForDonateBtn: function () {
        $('.donate__amount-item').on('click', function (e) {
            if (!$(this).hasClass('selected')) {
                $('.donate__amount-item').removeClass('selected');
                $(this).addClass('selected');
            }
            //if one of the predefined amounts
            if (!$(this).hasClass("donate__amount-item--other")) {
                //make the adjaction radio button chosen
                var selected_donation_radio_input = $(this).next();
                $(selected_donation_radio_input).trigger("click");
                $("input[name='donation_amount_other']").val("");
            }
            else {
                $("input[name='donation_amount']").val("");
            }
        });
    },
    controlExitButton: function () {
        var $headerHeight = $('.header').height();
        window.addEventListener("scroll", function () {
            if (window.scrollY > $headerHeight) {
                $('.exit-button').addClass('fixed');
            }
            else {
                $('.exit-button').removeClass('fixed');
            }
        });
    },
    initCarousels: function () {
        $('.atm__slider').slick({
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: false,
            dots: true,
            draggable: false,
            fade: true
        });
    },
    initAccordion: function () {
        //Accordeon o_O
        (function ($) {
            var allPanels = $('.accordion > dd').hide();
            $('.accordion > dt > a').click(function () {
                if ($(this).parent().hasClass('open')) {
                    return $(this).parent().removeClass('open').next().slideUp();
                }
                allPanels.slideUp().prev('dt').removeClass('open');
                $(this).parent().next().slideDown().prev('dt').addClass('open');
                return false;
            });
        })(jQuery);
    },
    initContactLocation: function () {
        //make the first location active
        $(".contact-locations__item").first().addClass("active");
        $('.contact-locations__item-title').on('click', function (e) {
            e.preventDefault();
            if (!$(this).parents('.contact-locations__item').hasClass('active')) {
                $('.contact-locations__item').removeClass('active');
                $(this).parents('.contact-locations__item').addClass('active');
            }
        });
    },
    installVideoFancybox: function () {
        $('.video-preview').fancybox({
            protect: true
        });
    },
    initHeader: function () {
        $('.header__nav > ul > li.has-children:not(".home-item")').on('mouseenter', function (e) {
            $('.megamenu__overlay').addClass('active');
        });
        $('.header__nav > ul > li.has-children').on('mouseleave', function (e) {
            $('.megamenu__overlay').removeClass('active');
        });
        $('.header__search-open').on('click', function (e) {
            e.preventDefault();
            $('.megamenu__overlay').toggleClass('active');
            $('.search__overlay').toggleClass('active');
            //focus on the search box
            setTimeout(function () {
                $("#query").focus();
            }, 500);
        });
        $('.header__nav-title').on('mouseenter', function () {
            $('.search__overlay').removeClass('active');
        });
        $(window).resize(function () {
            if ($(window).width() > 1366) {
                $($('.header__nav')[0]).removeClass('mobile');
            }
            else {
                $($('.header__nav')[0]).addClass('mobile');
            }
        });
        $(".header__nav-arrow").on('click', function () {
            $(this).next('.header__nav-hidden').toggleClass('active');
            var bg = $(this).css('background-image');
            bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
            image_url = "";
            if (bg.indexOf('arrow-down') > 0) {
                image_url = "/img/svg/icon-arrow-up.svg";
            }
            else {
                image_url = "/img/svg/icon-arrow-down.svg";
            }
            $(this).css('background-image', 'url(' + image_url + ')');
        });
        $('.header__burger').on('click', function (e) {
            e.preventDefault();
            $('.header__nav').addClass('active');
            $('body').addClass('fixed');
        });
        $('.header__nav-mobile-close').on('click', function (e) {
            e.preventDefault();
            $('.header__nav').removeClass('active');
            $('body').removeClass('fixed');
        });
        //check which page the site is currently on
        var body_classes = $('body').attr('class');
        if (body_classes.indexOf("template-home") >= 0) {
            $('.template-default .header > .logo-container').css('display', 'none');
            $('.template-contact .header > .logo-container').css('display', 'none');
        }
        else {
            $('.template-default .header .logo-container').css('display', 'block');
            $('.template-contact .header .logo-container').css('display', 'block');
            $('.template-contact-new .header .logo-container').css('display', 'block');
        }
    },
    installClickEventForNewsViewMoreBtn: function () {
        $("#view-more-btn").on("click", function (event) {
            event.preventDefault();
            //get the topic
            var topic = $('#topic').val();
            //get the order
            var time = $("#time").val();
            //get the current offset
            var news_offset = $("#news_offset").val();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            //send this postcode and distance back to server
            $.ajax({
                type: "POST",
                url: '/news/more-news',
                data: { topic: topic, time: time, news_offset: news_offset }
            }).done(function (response) {
                if (response != "no_more_news") {
                    $('.news-small').last().after(response);
                    //get the updated offset
                    var updated_offset = $("#updated_offset").val();
                    //set the new offset to news_offset input
                    $("#news_offset").val(updated_offset);
                    //remove the updated offset input
                    $("#updated_offset").remove();
                    //scrool to the last .news-small item with offset
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".news-small").last().offset().top - 150
                    }, 1000);
                }
            });
        });
    },
    installClickEventForHearAboutUsBtn: function () {
        $(".donate-form input[name='how_you_hear_about_us']").on('click', function () {
            if ($(this).val() == "other") {
                $("#whereOther").attr("disabled", false);
            }
            else {
                $("#whereOther").text("");
                $("#whereOther").attr("disabled", true);
            }
        });
    },
    installClickEventForPaymentMethodBtn: function () {
        $(".payment__method-button").on('click', function () {
            if (!$(this).hasClass('selected')) {
                $('.payment__method-button').removeClass('selected');
                $(this).addClass('selected');
            }
            var payment_method_radio_btn = $(this).next();
            $(payment_method_radio_btn).trigger("click");
        });
    },
    afterLoaded: function () {
        //$($('.donate__amount-item')[1]).trigger("click");
        //$($(".payment__method-button")[0]).trigger("click");
    },
    installClickEventForPaymentMethodRadioBtn: function () {
        $("input[name='payment_method']").on('click', function () {
            if ($(this).attr('id') == 'type_credit_card') {
                $('.credit__card_details').removeClass('hidden');
            }
            else {
                $('.credit__card_details').addClass('hidden');
            }
        });
    },
    initVideoMagnificPopup: function () {
        var elements = $('.st__video .st__video-button');
        if (!elements.length) {
            return false;
        }
        $(elements).each(function () {
            if ($(this).length) {
                $(this).magnificPopup({
                    type: 'iframe',
                    midClick: true,
                    key: 'youtube-video',
                    removeDelay: 500,
                    mainClass: 'mfp-fade'
                });
            }
        });
    },
    initPhotoGalleryPopup: function () {
        var elements = $('.content-gallery__list');
        if (!elements.length) {
            return false;
        }
        if (elements.length) {
            $(elements[0]).magnificPopup({
                delegate: 'a',
                type: 'image',
                midClick: true,
                removeDelay: 500,
                gallery: {
                    enabled: true
                },
                mainClass: 'mfp-fade',
                navigateByImgClick: true
            });
        }
    },
    initDisclaimerBtnsEventListener: function () {
        $(".floating-disclaimer .container .close").on("click", function (event) {
            var target = $(event.target), disclaimer = target.parents('.floating-disclaimer'), type = disclaimer.data('disclaimer-type');
            disclaimer.remove();
            $.ajax({
                url: '/cookie/disclaimer?disclaimer_type=' + type,
                data: { disclaimer_type: type }
            }).done(function (response) {
            });
        });
        $("#play-audio").on("click", function (event) {
            event.preventDefault();
            var audio = document.getElementById("audio");
            audio.play();
        });
    },
    initResizeEvent: function () {
        main.adjustTextSize();
        $(window).resize(throttle(function () {
            main.adjustTextSize();
        }, 300));
    },
    storeOriginalBreadCrumbs: function () {
        main.breadcrumbs = $($($(".atm__breadcrumbs")[0]).clone()).html();
    },
    adjustTextSize: function () {
        var window_width = $(window).width();
        //only truncate text if in mobile view
        if (window_width <= 568) {
            breadcrumbs_global = $(".atm__breadcrumbs")[0];
            //truncate a and span elements to 20 character with epillepsis style
            $(".atm__breadcrumbs a, .atm__breadcrumbs span").each(function () {
                if ($(this).text().length >= 20) {
                    $(this).text($(this).text().substring(0, 20) + "...");
                }
            });
        }
        else {
            $('.atm__breadcrumbs').remove();
            $('.atm > .container').append('<div class="atm__breadcrumbs">' + main.breadcrumbs + "</div>");
        }
    },
    initCmsDateFields: function () {
        $('.cmsform select[name$="_d"] option[value=""]').text('Day');
        $('.cmsform select[name$="_m"] option[value=""]').text('Month');
        $('.cmsform select[name$="_y"] option[value=""]').text('Year');
    },
    getCookie: function (cookieName) {
        var name = cookieName + "=";
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            var temp = allCookieArray[i].trim();
            if (temp.indexOf(name) == 0)
                return temp.substring(name.length, temp.length);
        }
        return "";
    },
    setCookie: function (cookieName, value, expireInHours) {
        var date = new Date();
        date.setTime(date.getTime() + (expireInHours * 60 * 60 * 1000));
        document.cookie = cookieName + "=" + value + "; expires=" + date.toGMTString();
    },
    /**
     * Handle header top/bottom navigation backdrop
     */
    handleNavigationBackdrop: function () {
        var topNavItems = $('.awo-header__top-navigation > ul > li');
        var topBackdrop = $('.awo-header__top-navigation--overlay');
        var bottomNavItems = $('.awo-header__bottom-navigation > ul > li');
        var bottomBackdrop = $('.awo-header__bottom-navigation--overlay');
        var desktopSearch = $('.awo-desktop-search');
        var bottomMenu = $('.awo-header__bottom');
        var transparentOverlay = $('.awo-header__top-navigation--overlay-transparent');
        // Handle top nav mouse entry
        topNavItems.on('mouseenter', function () {
            // Reset active menu items
            topNavItems.removeClass('active');
            bottomNavItems.removeClass('active');
            if ($(this).hasClass('have-children')) {
                // Set active menu item and trigger backdrops
                $(this).addClass('active');
                topBackdrop.addClass('active');
                desktopSearch.removeClass('is-active');
                transparentOverlay.addClass('active');
                // hide bottom navigation
                bottomBackdrop.removeClass('active');
                bottomMenu.removeClass('active');
            }
            else {
                // If active item has no children then dismiss backdrop
                topBackdrop.removeClass('active');
                desktopSearch.removeClass('is-active');
                transparentOverlay.removeClass('active');
            }
        });
        bottomNavItems.on('mouseenter', function () {
            // Reset active menu items
            bottomNavItems.removeClass('active');
            topNavItems.removeClass('active');
            if ($(this).hasClass('have-children')) {
                // Set active menu item and trigger backdrops
                $(this).addClass('active');
                bottomBackdrop.addClass('active');
                bottomMenu.addClass('active');
                desktopSearch.removeClass('is-active');
                transparentOverlay.addClass('active');
                // hide top navigation
                topBackdrop.removeClass('active');
            }
            else {
                // If active item has no children then dismiss backdrop
                bottomBackdrop.removeClass('active');
                bottomMenu.removeClass('active');
                desktopSearch.removeClass('is-active');
                transparentOverlay.removeClass('active');
            }
        });
        // Dismiss all backdrops when mouse enter transparent backdrop
        transparentOverlay.on('mouseenter', function () {
            // top navigation
            topNavItems.removeClass('active');
            topBackdrop.removeClass('active');
            desktopSearch.removeClass('is-active');
            transparentOverlay.removeClass('active');
            // bottom navigation
            bottomNavItems.removeClass('active');
            bottomBackdrop.removeClass('active');
            bottomMenu.removeClass('active');
            desktopSearch.removeClass('is-active');
            transparentOverlay.removeClass('active');
        });
    },
    /**
     * Handle header mobile navigation
     */
    handleMobileNavigation: function () {
        var hamburger = $('button.hamburger');
        var mobileMenu = $('.awo-mobile-menu');
        var headerTop = $('.awo-header__top');
        var mobileExpandItems = $('.awo-mobile-menu__navigation-expand-icon');
        var body = $('body');
        hamburger.on('click', function () {
            $(this).toggleClass('is-active');
            mobileMenu.toggleClass('is-active');
            headerTop.toggleClass('mobile-menu-active');
            body.toggleClass('overflow-hidden');
        });
        mobileExpandItems.on('click', function () {
            $(this).toggleClass('is-active');
            $(this).closest('.have-children').find('.awo-header__mobile-navigation--children').toggleClass('is-active');
            $(this).closest('.have-children').find('.awo-mobile-menu__navigation-title').toggleClass('is-active');
        });
    },
    /**
     * Handle footer mobile navigation
     */
    footerMobileNavigation: function () {
        var titles = $('.awo-footer__links-title > span');
        titles.on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('is-active');
            $(this).closest('.awo-footer_links-container').find('.awo-footer__links-group').toggleClass('is-active');
        });
    },
    /**
     * Watch search icon
     */
    watchSearchIcon: function () {
        var searchIcon = $('.awo-header__top-icons--search');
        var desktopSearch = $('.awo-desktop-search');
        var topNavItems = $('.awo-header__top-navigation > ul > li');
        var topBackdrop = $('.awo-header__top-navigation--overlay');
        var bottomNavItems = $('.awo-header__bottom-navigation > ul > li');
        var bottomMenu = $('.awo-header__bottom');
        var transparentOverlay = $('.awo-header__top-navigation--overlay-transparent');
        var bottomBackdrop = $('.awo-header__bottom-navigation--overlay');
        searchIcon.on('click', function () {
            desktopSearch.toggleClass('is-active');
            // Cancel header top and bottom menu
            topNavItems.removeClass('active');
            topBackdrop.removeClass('active');
            transparentOverlay.removeClass('active');
            // bottom navigation
            bottomNavItems.removeClass('active');
            bottomBackdrop.removeClass('active');
            bottomMenu.removeClass('active');
            transparentOverlay.removeClass('active');
        });
    },
    colourInvalidFormInputLabels: function () {
        var formButton = document.querySelector('input[name="cms_form_submit"]');
        if (formButton === null) {
            return;
        }
        formButton.addEventListener('click', function (e) {
            var inputs = document.querySelectorAll('input.formError');
            var invalidInputLabels = document.querySelectorAll('.hasError');
            invalidInputLabels.forEach(function (el) {
                el.classList.remove('hasError');
            });
            inputs.forEach(function (el) {
                var closestParent = el.closest('div.field_contain');
                var label = closestParent.querySelector('.field_name > label');
                var span = closestParent.querySelector('.field_name > span');
                label.classList.add('hasError');
                span.classList.add('hasError');
            });
        });
    },
    handleTopMenuOnScrollImproved: function () {
        $(window).on('scroll', {
            previousTop: 0,
        }, function () {
            var currentTop = $(window).scrollTop();
            var $mainNavigation = $('.awo-header');
            //check if user is scrolling up
            if (currentTop < this.previousTop) {
                //if scrolling up...
                //add class 'is-visible' to the main navigation
                //if currentTop == 0, remove 'is-fixed' and 'is-visible' classes
                $mainNavigation.addClass('is-visible');
                if (currentTop <= 5) {
                    $mainNavigation.removeClass('is-visible');
                    $mainNavigation.removeClass('is-fixed');
                }
            }
            else {
                //if scrolling down...
                //add the 'is-fixed' class to the main navigation as soon as it is no longer visible
                //add class 'is-visible' to the main navigation
                if (currentTop > 5) {
                    $mainNavigation.addClass('is-fixed');
                }
                $mainNavigation.removeClass('is-visible');
            }
            //set previousTop for the next iteration
            this.previousTop = currentTop;
        });
    },
    /**
     * Animate page content entry on scroll
     */
    animateOnScroll: function () {
        var revealController = new ScrollMagic.Controller();
        var revealElements = document.getElementsByClassName('reveal');
        var animateNumbers = document.querySelectorAll('.animateNumbers');
        var socialWidget = document.querySelector('.reveal-widget');
        for (var i = 0; i < revealElements.length; i++) {
            new ScrollMagic.Scene({
                triggerElement: revealElements[i],
                offset: 10,
                reverse: false,
                triggerHook: 0.9,
            })
                .setClassToggle(revealElements[i], 'revealed')
                .addTo(revealController);
        }
        animateNumbers.forEach(function (el) {
            new ScrollMagic.Scene({
                triggerElement: el,
                offset: 10,
                reverse: false,
                triggerHook: 0.9,
            })
                .on('enter', function (e) {
                main.animateThreeNumbersOnHome();
                awoComponents.threeNumbersComponent(el);
            })
                .addTo(revealController);
        });
        new ScrollMagic.Scene({
            triggerElement: socialWidget,
            offset: 10,
            reverse: false,
            triggerHook: 0.9,
        })
            .setClassToggle(socialWidget, 'revealed-widget')
            .addTo(revealController);
    },
    /**
     * Animate three numbers block on homepage
     */
    animateThreeNumbersOnHome: function () {
        var counterUp = window.counterUp.default;
        var numbers = document.querySelectorAll('.awo-impact__item-number');
        numbers.forEach(function (el) {
            counterUp(el, {
                duration: 2000,
                delay: 16
            });
        });
    },
};
