/// <reference path="../references.ts"/>
var awoComponents = {
    /**
     * Tabbed container component swiper instance
     */
    tabbedContainerSwiperInstance: null,
    /**
     * Video player instances
     */
    videoPlayerinstances: [],
    /**
     * Initialise all the components sitewide
     */
    init: function () {
        awoComponents.initTabbedContainerComponent();
        awoComponents.initVideoPlayerComponent();
    },
    /**
     * Initialise tabbed container component
     */
    initTabbedContainerComponent: function () {
        awoComponents.tabbedContainerSwiperInstance = new Swiper('.awo-tabbed-container-swiper', {
            spaceBetween: 20,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoHeight: true,
        });
    },
    /**
     * Tabbed container functionality implemented using
     * Alpine. Depends on initTabbedContainerComponent.
     *
     * @returns object
     */
    alpineTabbedContainerComponent: function () {
        return {
            activeIndex: 0,
            change: function (index) {
                var swiper = awoComponents.tabbedContainerSwiperInstance;
                swiper.slideTo(index);
                this.activeIndex = index;
            },
        };
    },
    /**
     * Documents items row component load items
     * in fragments
     *
     * @returns object
     */
    alpineDocumentItemsRowComponent: function () {
        return {
            initItems: 5,
            active: 5,
            items: null,
            init: function () {
                var _this = this;
                this.items = document.querySelectorAll('.document-items-row__item');
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 5; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    /**
     * Accordion component load items
     * in fragments
     *
     * @returns object
     */
    alpineAccordionComponent: function () {
        return {
            initItems: 10,
            active: 10,
            items: null,
            init: function () {
                var _this = this;
                this.items = document.querySelectorAll('.awo-accordion__item-head');
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 5; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    alpineTwoColLinksComponent: function () {
        return {
            initItems: 10,
            active: 10,
            items: null,
            xinit: function (itemCount) {
                var _this = this;
                if (itemCount === void 0) { itemCount = 10; }
                this.items = document.querySelectorAll('.awo-two-col-links__grid > .awo-two-col-links__grid-item');
                this.initItems = itemCount;
                this.active = itemCount;
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 10; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    /**
     * Initialise video player components
     */
    initVideoPlayerComponent: function () {
        var videos = document.querySelectorAll('.awo-video-player__button');
        var isIPhone = navigator.userAgent.indexOf('iPhone') > -1;
        videos.forEach(function (el) {
            awoComponents.videoPlayerinstances[el.getAttribute('player-id')] = GLightbox({
                elements: [
                    {
                        'href': el.getAttribute('video-url'),
                        'type': 'video',
                    },
                ],
                autoplayVideos: isIPhone ? false : true,
                plyr: {
                    config: {
                        muted: false,
                        youtube: {
                            autoplay: 0,
                            playsinline: 1,
                        },
                    }
                }
            });
            el.addEventListener('click', function (e) {
                e.preventDefault();
                awoComponents.videoPlayerinstances[this.getAttribute('player-id')].open();
            });
        });
    },
    /**
     * Animate three numbers component
     *
     * @param element parent element object
     */
    threeNumbersComponent: function (element) {
        var counterUp = window.counterUp.default;
        var numbers = element.querySelectorAll('.awo-three-numbers__grid-item__name');
        numbers.forEach(function (el) {
            counterUp(el, {
                duration: 2000,
                delay: 16
            });
        });
    },
};
